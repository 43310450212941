// Dimensions
$primary-spacing: 20px;
$base-font-size: 16px;
$mobile-font-size: 14px;
$mobile-tile-length: 275px;
$desktop-tile-length: 230px;

// Colors: https://coolors.co/f4faff-555b6e-41658a-000000-ffffff
$light-color: #f4faff;
$dark-color: #555b6e;
$accent-color: #41658a;
$shaded-dark-color: rgba($dark-color, 0.8);
$white: #fff;
$black: #000;
$border-color: rgba($dark-color, 0.2);

// Other
$font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
@import url('https://fonts.googleapis.com/css?family=Lato');
$font-family: 'Lato', sans-serif;

$z-index-medium: 10;
$z-index-high: 20;
