.work-tiles {
  $tile-padding: $primary-spacing;

  display: grid;
  grid-gap: $primary-spacing;
  grid-template-columns: repeat(auto-fill, $mobile-tile-length);
  grid-template-rows: repeat(auto-fit, $mobile-tile-length + $tile-padding*2);
  justify-content: center;
  height: 100%;

  @include breakpoint(above-tablet) {
    grid-template-columns: repeat(auto-fill, $desktop-tile-length);
    grid-template-rows: repeat(auto-fit, $desktop-tile-length + $tile-padding*2);

    &__item.work-tiles__item--collapsed {
      height: $desktop-tile-length;
    }
  }

  &__item {
    @include box-shadow(false, true, true, false);
    @include rounded-border-1;
    height: $mobile-tile-length;
    padding: $tile-padding;
    overflow: hidden;
    transition: box-shadow 0.2s ease-in-out;
    border: 1px solid $border-color;
    background-color: $white;
    text-align: center;

    &:hover,
    &:focus {
      @include box-shadow(false, true, true, false, 2);
      outline: initial;
    }

    &--hidden {
      display: none;
    }

    &--collapsed {
      cursor: pointer;
      // Get rid of weird blue highlight on click
      -webkit-tap-highlight-color: rgba($white, 0);
    }

    &--collapsed &__child {
      display: flex;
      position: relative;
      flex-direction: column;
      width: 100%;
      height: 100%;

      &__text-content {
        flex: 1;
      }

      h1 {
        font-size: 1.2em;
      }

      img {
        flex: 1;
        max-width: 100%;
        max-height: 50%;
      }

      &__indicator {
        top: -11px;
        left: -40px;
        width: 75px;
      }
    }

    &__child {
      &__indicator {
        // FIXME #39: While collapsing it still seems to do a funny thing
        @include box-shadow(true, false, true, false);
        position: absolute;
        padding: $primary-spacing/4 0;
        transform: rotate(-45deg);
        background: $accent-color;
        color: $light-color;
        font-size: 0.7rem;
        font-weight: 700;
        line-height: 7px;
      }
    }

    &--expanding {
      z-index: $z-index-high;
    }

    &--selected {
      display: flex;
      position: fixed;
      z-index: $z-index-high;
      top: 17.5%;
      left: 10%;
      // I'd love to set max width/heights for this guy
      // But it proves difficult to do that and still center
      // while being position fixed :/
      width: 80%;
      height: 65%;
      padding: $primary-spacing/4;
    }

    &--selected.work-tiles__item--expanding &__child {
      display: none;
    }

    &--selected &__child {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 700px;
      // Set height to 100% - the top and bottom margin (aka $primary-spacing*3).
      height: calc(100% - #{$primary-spacing*3});
      margin: $primary-spacing*1.5 auto;
      overflow-y: scroll;

      > * {
        flex-shrink: 0;
      }

      h1 {
        margin-top: 0;
        margin-bottom: $primary-spacing/2;
      }

      &__sub-headings {
        display: flex;
        flex-direction: column;
      }

      &__sub-headings > * {
        margin: $primary-spacing/8 $primary-spacing;
      }

      img {
        flex-shrink: 1;
        min-height: 25%;
        max-height: 25%;
        margin-top: $primary-spacing/2;
      }

      &__long-description {
        margin: $primary-spacing*1.25 $primary-spacing $primary-spacing/1.25 $primary-spacing;
      }

      &__close-btn {
        @include focus-hover-2;
        position: absolute;
        top: $primary-spacing/2;
        right: $primary-spacing/2;
        border: 0;
        background: none;
        color: $dark-color;
        cursor: pointer;
      }

      &__indicator {
        top: 9px;
        left: -31px;
        width: 100px;
      }
    }
  }
}
