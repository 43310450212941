.jkj-select {
  &__control {
    @include focus-hover-1;

    @include breakpoint(tablet-and-below) {
      font-size: 2.625rem;
    }

    @include breakpoint(mobile) {
      font-size: 3rem;
    }
  }

  &__value-container {
    justify-content: center;

    @include breakpoint(above-small-laptop) {
      @include center-horizontal;
      justify-content: initial;
    }
  }

  &__placeholder {
    max-width: calc(100% - 8px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
