@mixin breakpoint($points...) {
  // Not sure if theres a better way to do this without duplicating
  // the @content for each breakpoint.. good enough for now.
  @each $point in $points {
    @if $point == above-desktop {
      @media (min-width: 1200px) { @content ; }
    } @else if $point == above-laptop {
      @media (min-width: 992px) { @content ; }
    } @else if $point == above-small-laptop {
      @media (min-width: 925px) { @content ; }
    } @else if $point == above-tablet {
      @media (min-width: 769px) { @content ; }
    } @else if $point == tablet-and-below {
      @media (max-width: 768px) { @content ; }
    } @else if $point == above-mobile {
      @media (min-width: 425px) { @content ; }
    } @else if $point == mobile {
      @media (max-width: 425px) { @content ; }
    } @else {
      @error 'Unrecognized breakpoint #{$point}';
    }
  }
}

@mixin inverted-colors {
  background-color: $dark-color;
  color: $light-color;
}

@mixin box-shadow($top, $right, $bottom, $left, $multiplier: 1) {
  $shadow-blur: 2px;
  $shadow-offset: 3px*$multiplier;
  $top-offset: if($top, -1*$shadow-offset, 0);
  $right-offset: if($right, $shadow-offset, 0);
  $bottom-offset: if($bottom, $shadow-offset, 0);
  $left-offset: if($left, -1*$shadow-offset, 0);

  box-shadow: $right-offset $bottom-offset $shadow-blur $border-color,
    $left-offset $top-offset $shadow-blur $border-color;
}
@mixin top-box-shadow {
  @include box-shadow(true, false, false, false);
}
@mixin right-box-shadow {
  @include box-shadow(false, true, false, false);
}
@mixin bottom-box-shadow {
  @include box-shadow(false, false, true, false);
}
@mixin left-box-shadow {
  @include box-shadow(false, false, false, true);
}

@mixin center-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@mixin focus-hover-1 {
  transition: filter 0.2s ease-in-out;

  &:hover,
  &:focus {
    filter: drop-shadow(6px 6px 2px rgba($black, 0.5));
    outline: none;
  }
}

@mixin focus-hover-2 {
  &:hover,
  &:focus {
    color: $shaded-dark-color;
  }
}

@mixin rounded-border-1 {
  border: 2px solid;
  border-radius: 4px;
}
