.external-reference-list {
  display: flex;
  align-items: baseline;
  justify-content: center;

  @include breakpoint(tablet-and-below) {
    flex-direction: column;
    align-items: center;
  }

  > h2 {
    margin: 0;
    padding-right: $primary-spacing/4;
    font-size: 1rem;
  }

  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0;
    padding: 0;
  }

  li {
    display: flex;
    padding-right: $primary-spacing/6;
  }

  &__and {
    padding-right: $primary-spacing/6;
  }
}
