.home {
  $header-height: 250px;
  $header-height-medium: 180px;
  $header-height-small: 127px;

  display: grid;
  grid-template-areas:
    'header'
    'main'
    'footer';
  grid-template-rows: $header-height 1fr 80px;
  min-height: 100vh;

  &__header {
    $header-transition-duration: 0.1s;

    @include inverted-colors;
    @include bottom-box-shadow;
    display: flex;
    position: sticky;
    z-index: $z-index-medium;
    top: 0;
    grid-area: header;
    flex-direction: column;
    justify-content: center;
    height: $header-height;
    transition: height $header-transition-duration ease-in-out;
    font-size: 4rem;

    @include breakpoint(tablet-and-below) {
      font-size: 2.625rem;
    }

    @include breakpoint(mobile) {
      font-size: 3rem;
    }

    &.shrunk {
      height: $header-height-medium;

      @include breakpoint(mobile, above-laptop) {
        height: $header-height-small;
      }
    }

    &:not(.shrunk) .container {
      padding-bottom: 0;
    }

    .container {
      display: flex;
      flex-direction: column;
      align-items: center;

      @include breakpoint(above-small-laptop) {
        @include center-horizontal;
        align-items: baseline;
      }

    }

    &__name {
      white-space: nowrap;
    }

    &__search__control-container {
      display: flex;
      width: 100%;
      min-width: 20rem;
      max-width: 31rem;
    }

    &__intro {
      @include center-horizontal;
      max-height: 60px;
      padding: 0 $primary-spacing*2 $primary-spacing $primary-spacing*2;
      overflow: hidden;
      transition: height $header-transition-duration ease-in-out
        , padding $header-transition-duration ease-in-out
        , margin $header-transition-duration ease-in-out
        , opacity $header-transition-duration ease-in-out;
      font-size: 1.5rem;
      text-align: center;
    }

    &.shrunk &__intro {
      max-height: 0;
      margin: 0;
      padding: 0;
      opacity: 0;
    }
  }

  &__main {
    grid-area: main;
  }

  &__footer {
    z-index: $z-index-medium;
    grid-area: footer;
  }
}
