html {
  min-width: 375px;
  font-family: $font-family;
  font-size: $base-font-size;

  @include breakpoint(mobile) {
    font-size: $mobile-font-size;
  }
}

body {
  min-width: 320px;
  margin: 0;
  padding: 0;
  background-color: $light-color;
  color: $dark-color;
}

.overlay {
  display: none;
}

.body--with-modal {
  overflow: hidden;

  .overlay {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($dark-color, 0.75);
  }
}

.container {
  width: calc(100vw - 2*20px);
  max-width: 1045px;
  margin-right: auto;
  margin-left: auto;
  padding: $primary-spacing;
}

